import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Grid } from "@mui/material";
import "./Faq.css";
export default function Faq() {
  const [style, setStyle] = React.useState(0);

  const changeStyle = () => {
    setStyle(!style);
  };
  const [style2, setStyle2] = React.useState(0);

  const changeStyle2 = () => {
    setStyle2(!style2);
  };
  const [style3, setStyle3] = React.useState(0);

  const changeStyle3 = () => {
    setStyle3(!style3);
  };
  const [style4, setStyle4] = React.useState(0);

  const changeStyle4 = () => {
    setStyle4(!style4);
  };
  const [style5, setStyle5] = React.useState(0);

  const changeStyle5 = () => {
    setStyle5(!style5);
  };

  const [style6, setStyle6] = React.useState(0);

  const changeStyle6 = () => {
    setStyle6(!style6);
  };

  const [style7, setStyle7] = React.useState(0);

  const changeStyle7 = () => {
    setStyle7(!style7);
  };

  const [ques, setQues] = React.useState(0);

  return (
    <>
      <div id="Faq" className="faq__body">
        <Grid className="grid" container alignItems="center" spacing={2}>
          <Grid style={{ textAlign: "center" }} item xs={12} md={12}>
            <Accordion
              style={{ border: "1px solid black", backgroundColor: "white" }}
            >
              <AccordionSummary
                className={style ? "cont faq" : "cont2 faq"}
                onClick={changeStyle}
                expandIcon={
                  !style ? (
                    <ExpandMoreIcon style={{ color: "white" }} />
                  ) : (
                    <RemoveIcon style={{ color: "white" }} />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={style ? "faqtext1" : "faqtext3"}>
                  On what blockchain is Alien Society launching?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq__ans">
                  Our official launch is going to take place on the Solana
                  Blockchain.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{ border: "1px solid black", backgroundColor: "white" }}
            >
              <AccordionSummary
                className={style2 ? "cont faq" : "cont2 faq"}
                onClick={changeStyle2}
                expandIcon={
                  !style2 ? (
                    <ExpandMoreIcon style={{ color: "white" }} />
                  ) : (
                    <RemoveIcon style={{ color: "white" }} />
                  )
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={style2 ? "faqtext1" : "faqtext3"}>
                  What Solana wallets should I use when minting?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq__ans">
                  We will be using Phantom, Sollet and Solflare wallets for the
                  website connection. Out of these ones you can use whatever you
                  prefer.{" "}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ border: "1px solid black", backgroundColor: "white" }}
            >
              <AccordionSummary
                className={style3 ? "cont faq" : "cont2 faq"}
                onClick={changeStyle3}
                expandIcon={
                  !style3 ? (
                    <ExpandMoreIcon style={{ color: "white" }} />
                  ) : (
                    <RemoveIcon style={{ color: "white" }} />
                  )
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={style3 ? "faqtext1" : "faqtext3"}>
                  Where & when is the launch?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq__ans">
                  Our launch will take place on alien-society.com on the 9th of
                  february.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ backgroundColor: "white", border: "1px solid black" }}
            >
              <AccordionSummary
                className={style4 ? "cont faq" : "cont2 faq"}
                onClick={changeStyle4}
                expandIcon={
                  !style4 ? (
                    <ExpandMoreIcon style={{ color: "white" }} />
                  ) : (
                    <RemoveIcon style={{ color: "white" }} />
                  )
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={style4 ? "faqtext1" : "faqtext3"}>
                  How much does it cost to mint an Alien?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq__ans">
                  The mint price of an Alien will be 1 SOL.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ backgroundColor: "white", border: "1px solid black" }}
            >
              <AccordionSummary
                className={style5 ? "cont faq" : "cont2 faq"}
                onClick={changeStyle5}
                expandIcon={
                  !style5 ? (
                    <ExpandMoreIcon style={{ color: "white" }} />
                  ) : (
                    <RemoveIcon style={{ color: "white" }} />
                  )
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={style5 ? "faqtext1" : "faqtext3"}>
                  Is there any pre-sale?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq__ans">
                  Yes, we have a pre-sale planned. More details will come closer
                  to launch!
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ backgroundColor: "white", border: "1px solid black" }}
            >
              <AccordionSummary
                className={style6 ? "cont faq" : "cont2 faq"}
                onClick={changeStyle6}
                expandIcon={
                  !style6 ? (
                    <ExpandMoreIcon style={{ color: "white" }} />
                  ) : (
                    <RemoveIcon style={{ color: "white" }} />
                  )
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={style6 ? "faqtext1" : "faqtext3"}>
                  Is there a limit on how many we can get?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq__ans">
                  There is no limit per wallet. If you are quick enough you can
                  snatch as many as you can. However, there will only be 1 Alien
                  given for 1 transaction.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ backgroundColor: "white", border: "1px solid black" }}
            >
              <AccordionSummary
                className={style7 ? "cont faq" : "cont2 faq"}
                onClick={changeStyle7}
                expandIcon={
                  !style7 ? (
                    <ExpandMoreIcon style={{ color: "white" }} />
                  ) : (
                    <RemoveIcon style={{ color: "white" }} />
                  )
                }
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={style7 ? "faqtext1" : "faqtext3"}>
                  Which link should I use to mint the Aliens?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq__ans">
                  Our mint link will be dropped in our discord server
                  https://discord.gg/aliensociety.{" "}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        {/* <div>
          <div className="sign_question">
            <div
              type="button"
              class=""
              data-toggle="collapse"
              data-target="#demo"
            >
              <img
                className="plus"
                onClick={() => setQues(!style)}
                src={!style ? "./assets/plus.png" : "./assets/remove.png"}
                alt=""
              />
            </div>
            <div className="faqtext3">QUESTION ?</div>
          </div>

          <div id="demo" className="faq__ans" class="collapse">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </div>
        </div>
        <div>


        </div>
        <div>
          <div className="sign_question">
            <div
              type="button"
              class=""
              data-toggle="collapse2"
              data-target="#demo2"
            >
              <img
                className="plus"
                onClick={() => setStyle2(!style2)}
                src={!style2 ? "./assets/plus.png" : "./assets/remove.png"}
                alt=""
              />
            </div>

            <div className="faqtext3">QUESTION ?</div>
          </div>

          <div id="demo2" className="faq__ans" class="collapse2">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </div>
        </div>
        <div>
          <div className="sign_question">
            <div
              type="button"
              class=""
              data-toggle="collapse3"
              data-target="#demo3"
            >
              <img
                className="plus"
                onClick={() => setStyle3(!style3)}
                src={!style3 ? "./assets/plus.png" : "./assets/remove.png"}
                alt=""
              />
            </div>

            <div className="faqtext3">QUESTION ?</div>
          </div>

          <div id="demo3" className="faq__ans" class="collapse3">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </div>
        </div>
        <div>
          <div className="sign_question">
            <div
              type="button"
              class=""
              data-toggle="collapse4"
              data-target="#demo4"
            >
              <img
                className="plus"
                onClick={() => setStyle4(!style4)}
                src={!style4 ? "./assets/plus.png" : "./assets/remove.png"}
                alt=""
              />
            </div>

            <div className="faqtext3">QUESTION ?</div>
          </div>

          <div id="demo4" className="faq__ans" class="collapse4">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </div>
        </div> */}
      </div>
    </>
  );
}
