import React from "react";

function Section7() {
  return (
    <div>
      <img
        width="100%"
        height="100%"
        style={{ backgroundAttachment: "fixed" }}
        src="./assets/section7_img.png"
        alt=""
      ></img>
    </div>
  );
}

export default Section7;
