import React, { useState } from "react";
import { Squash as Hamburger } from "hamburger-react";
import "./Navbar.css";
function Navbar() {
  const [click, setClick] = useState(0);
  return (
    <div className="nav">
      <div className="navbar__body">
        <div className=" ">
          <img className="nav__disc" src="./assets/nav__twitter.png" alt="" />
        </div>
        <div className="">
          <img className="nav__twitter" src="./assets/nav__disc.png" alt="" />
        </div>
        <div onClick={() => setClick(!click)} className="">
          {click ? (
            <img className="nav__twitter" src="./assets/close.png" alt="" />
          ) : (
            <img className="nav__twitter" src="./assets/hamburger.png" alt="" />
          )}
        </div>
        <div className={click ? "nav__list" : "nav__list__disappear"}>
          <div
            onClick={() => window.scrollTo({ bottom: 0, behavior: "smooth" })}
          >
            Our Story
          </div>
          <div>Our Team</div>
          <div>About Yolkies</div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
