import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Parallax } from "react-scroll-parallax";
import "./Section2.css";
import MediaQuery from "react-responsive";
import { Parallax as Par, ParallaxLayer } from "@react-spring/parallax";
import RubberBand from "react-reveal/RubberBand";
function Section2() {
  return (
    <div className="section2">
      <img className="yolk__symbol" src="./assets/mid.svg" alt="" />
      <Box className="box2" sx={{ flexGrow: 1 }}>
        <Grid spacing={2} container>
          <Grid className="section2__yolk" item xs={12} md={12} lg={6}>
            <Parallax className="custom-class" x={[-30, 0]} tagOuter="figure">
              <RubberBand>
                <img src="./assets/2 24.png" className="section2__egg" alt="" />
              </RubberBand>
            </Parallax>
          </Grid>

          <Grid className="section2_right" item xs={12} md={12} lg={6}>
            <Grid container>
              <MediaQuery maxWidth={1024}>
                <Grid className="section2__title" item xs={12} md={12} lg={10}>
                  FOUR THOUSAND FOUR HUNDRED & FOURTY FOUR
                </Grid>
                <Grid
                  className="section2__subtitle"
                  item
                  xs={12}
                  md={12}
                  lg={12}
                >
                  UNIQUE YOLKIES
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="section2__text">
                    Yolk Society is a collection of 4,444 individually designed
                    and created Yolkies on the Ethereum Blockchain. Yolkie NFTs
                    are based on over 100+ distinct original attributes creating
                    over 15,000,000 combinations. These one of a kind eggs will
                    be forever on the blockchain.
                  </div>
                </Grid>

                <Grid className="section2_btn" item xs={12} md={12} lg={12}>
                  <div className="btns">
                    <div className="solsea__btn">
                      <div>
                        <img
                          src="./assets/logosea.svg"
                          className="section2__logo"
                          alt=""
                        />
                      </div>
                      <div className="viewsolsea">View on Opensea</div>
                    </div>
                  </div>
                </Grid>
              </MediaQuery>
              <MediaQuery minWidth={1025}>
                <Parallax
                  className="custom-class"
                  y={[30, -30]}
                  tagOuter="figure"
                >
                  <Grid
                    className="section2__title"
                    item
                    xs={12}
                    md={12}
                    lg={10}
                  >
                    FOUR THOUSAND FOUR HUNDRED & FOURTY FOUR
                  </Grid>
                  <Grid
                    className="section2__subtitle"
                    item
                    xs={12}
                    md={12}
                    lg={12}
                  >
                    UNIQUE YOLKIES
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <div className="section2__text">
                      Yolk Society is a collection of 4,444 individually
                      designed and created Yolkies on the Ethereum Blockchain.
                      Yolkie NFTs are based on over 100+ distinct original
                      attributes creating over 15,000,000 combinations. These
                      one of a kind eggs will be forever on the blockchain.
                    </div>
                  </Grid>

                  <Grid className="section2_btn" item xs={12} md={12} lg={12}>
                    <div className="btns">
                      <div className="solsea__btn">
                        <div>
                          <img
                            src="./assets/logosea.svg"
                            className="section2__logo"
                            alt=""
                          />
                        </div>
                        <div className="viewsolsea">View on Opensea</div>
                      </div>
                    </div>
                  </Grid>
                </Parallax>
              </MediaQuery>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Section2;
