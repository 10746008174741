import { Box, Grid } from "@mui/material";
import React from "react";
import "./Section3.css";
import Video from "./Video";
import { Parallax } from "react-scroll-parallax";
function Section3() {
  return (
    <div className="roadmap">
      <div className="our__roadmap">our roadmap</div>
      <div className="text__roadmap">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris purus
        dapibus pellentesque rhoncus dignissim pharetra. Pretium amet erat a
        tincidunt egestas. Et magna amet mus commodo. Ornare odio proin tortor
        sed cum. Ac est cursus bibendum in. Cum at pretium turpis est elementum
        sit porttitor odio. Faucibus consequat feugiat sed in interdum.
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid
            style={{ textAlign: "left" }}
            className="leftgrid"
            item
            md={0}
            lg={1}
          ></Grid>
          <Grid
            style={{ textAlign: "left" }}
            className="leftgrid"
            item
            md={0}
            lg={8}
          >
            <div>
              <div className="vl1"></div>
            </div>
            <div className="title1">Lorem ipsum dolor sit amet</div>
            <div className="text1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel
              condimentum lectus in mattis vel. Sed adipiscing et sollicitudin
              et semper tristique. Sit ipsum venenatis curabitur dis nibh lectus
              venenatis, ornare.
            </div>

            <div>
              <div className="vl2"></div>
            </div>
            <div className="title1">Lorem ipsum dolor sit amet</div>
            <div className="text1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel
              condimentum lectus in mattis vel. Sed adipiscing et sollicitudin
              et semper tristique. Sit ipsum venenatis curabitur dis nibh lectus
              venenatis, ornare.
            </div>

            <div>
              <div className="vl2"></div>
            </div>
            <div className="title1">Lorem ipsum dolor sit amet</div>
            <div className="text1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel
              condimentum lectus in mattis vel. Sed adipiscing et sollicitudin
              et semper tristique. Sit ipsum venenatis curabitur dis nibh lectus
              venenatis, ornare.
            </div>

            <div>
              <div className="vl2"></div>
            </div>
            <div className="title1">Lorem ipsum dolor sit amet</div>
            <div className="text1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel
              condimentum lectus in mattis vel. Sed adipiscing et sollicitudin
              et semper tristique. Sit ipsum venenatis curabitur dis nibh lectus
              venenatis, ornare.
            </div>

            <div>
              <div className="vl2"></div>
            </div>
            <div className="title1">Lorem ipsum dolor sit amet</div>
            <div className="text1">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel
              condimentum lectus in mattis vel. Sed adipiscing et sollicitudin
              et semper tristique. Sit ipsum venenatis curabitur dis nibh lectus
              venenatis, ornare.
            </div>
            <div className="vl1"></div>
          </Grid>
          <Grid
            className="video_grid"
            style={{ textAlign: "left" }}
            item
            xs={0}
            md={0}
            lg={3}
          >
            <Parallax className="custom-class" x={[0, 0]} tagOuter="figure">
              <Video />
            </Parallax>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Section3;
