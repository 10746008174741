import React from "react";
import "./Section1.css";
function Section1() {
  return (
    <div className="section1">
      <img className="yolk" src="./assets/logo__white.png" alt="" />
      {/* <img className="yolk__bag" src="./assets/Group 86.png" alt="" /> */}

      <img className="yolk__bag" src="./assets/yolkie.png" alt="" />
    </div>
  );
}

export default Section1;
