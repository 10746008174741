import React, { useEffect } from "react";
import { render } from "react-dom";
import mov from "./cubes.mp4";
import "./Section3.css";

const registerVideo = (bound, video) => {
  bound = document.querySelector(bound);
  video = document.querySelector(video);
  const scrollVideo = () => {
    if (video.duration) {
      const distanceFromTop =
        window.scrollY + bound.getBoundingClientRect().top;
      const rawPercentScrolled =
        (window.scrollY - distanceFromTop) /
        (bound.scrollHeight - window.innerHeight);
      const percentScrolled = Math.min(Math.max(rawPercentScrolled, 0), 1);

      video.currentTime = video.duration * percentScrolled;
    }
    requestAnimationFrame(scrollVideo);
  };
  requestAnimationFrame(scrollVideo);
};

function Video() {
  useEffect(() => {
    registerVideo("#bound-one", "#bound-one video");
  }, []);

  return (
    <div>
      <div id="bound-one" className="scroll-bound">
        <div className="content">
          <video width="100%" muted>
            <source src={mov} />
          </video>
        </div>
      </div>
      {/* <div id="bound-two" className="scroll-bound">
        <div className="content">
          <video width="600" muted preload>
            <source src={mov} />
          </video>
        </div>
      </div>
      <div id="bound-three" className="scroll-bound">
        <div className="content">
          <video width="600" muted preload>
            <source src={mov} />
          </video>
        </div>
      </div> */}
    </div>
  );
}

export default Video;
