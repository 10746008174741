import React from "react";
import "./Section6.css";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Parallax } from "react-scroll-parallax";
function Section6() {
  return (
    <div>
      <Parallax className="custom-class" x={[0, 0]} tagOuter="figure">
        <Box className="box6" sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={12} lg={6}></Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Grid className="section6_grid" alignItems="flex-start" container>
                <Grid className="community" item xs={12} md={12} lg={12}>
                  JOIN THE COMMuNITY
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="community__text">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Lectus semper sed nisi, dignissim arcu eros, adipiscing dui
                    arcu. Tristique arcu volutpat ac duis urna pulvinar eu
                    aliquam. Enim tristique volutpat nascetur nibh lorem nisi,
                    orci risus pharetra.
                  </div>
                </Grid>
                <Grid className="community__btns" item xs={12} md={12} lg={12}>
                  <div className="community__btn discCommunity">
                    <img src="./assets/comDisc.png" alt=" "></img>
                  </div>
                  <div className="community__btn twitterCommunity">
                    <img src="./assets/comTwitter.png" alt=" "></img>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Parallax>
    </div>
  );
}

export default Section6;
