import React from "react";
import MediaQuery from "react-responsive";
import Faq from "./Faq";
import "./Section4.css";
import { Parallax } from "react-scroll-parallax";

function Section4() {
  return (
    <>
      <div className="section4">
        <div className="section4__logo">
          <img className="mid__logo" src="./assets/mid.svg" alt="" />
        </div>
        <div className="section4__body">
          <MediaQuery minWidth={1025}>
            <Parallax className="custom-class" x={[10, -20]} tagOuter="figure">
              <div className="why">WHY WE’RE DOING THIS</div>
              <div className="title">SLOWLY DOES IT</div>
              <div className="text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Scelerisque congue maecenas eros, arcu vestibulum. Aliquet
                tristique ipsum quis viverra. Nisi, et a sagittis, egestas
                pharetra iaculis. Mattis euismod gravida semper neque viverra
                aliquam metus, eget. Sollicitudin feugiat eu venenatis tellus
                amet risus egestas. Eget aliquam eget ullamcorper a quis
                ultrices in dignissim aliquam. Quam sed ultrices nisi, tellus
                semper. Risus sed sit aliquam a sit velit.
              </div>
            </Parallax>
          </MediaQuery>
          <MediaQuery maxWidth={1024}>
            <Parallax className="custom-class" x={[0, 0]} tagOuter="figure">
              <div className="why">WHY WE’RE DOING THIS</div>
              <div className="title">SLOWLY DOES IT</div>
              <div className="text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Scelerisque congue maecenas eros, arcu vestibulum. Aliquet
                tristique ipsum quis viverra. Nisi, et a sagittis, egestas
                pharetra iaculis. Mattis euismod gravida semper neque viverra
                aliquam metus, eget. Sollicitudin feugiat eu venenatis tellus
                amet risus egestas. Eget aliquam eget ullamcorper a quis
                ultrices in dignissim aliquam. Quam sed ultrices nisi, tellus
                semper. Risus sed sit aliquam a sit velit.
              </div>
            </Parallax>
          </MediaQuery>

          <MediaQuery minWidth={1025}>
            <Parallax className="custom-class" x={[10, -20]} tagOuter="figure">
              <div className="what">WHAT YOU OWN</div>
              <div className="title2">WHAT YOUR PURCHASE DOES</div>
              <div className="text">
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                  <li>Scelerisque congue maecenas eros, arcu vestibulum.</li>
                  <li>Aliquet tristique ipsum quis viverra.</li>
                  <li>Nisi, et a sagittis, egestas pharetra iaculis.</li>
                  <li>
                    Mattis euismod gravida semper neque viverra aliquam metus,
                    eget.
                  </li>
                  <li>
                    Sollicitudin feugiat eu venenatis tellus amet risus egestas.
                  </li>
                </ul>
              </div>
            </Parallax>
          </MediaQuery>
          <MediaQuery maxWidth={1024}>
            <Parallax className="custom-class" x={[0, 0]} tagOuter="figure">
              <div className="what">WHAT YOU OWN</div>
              <div className="title2">WHAT YOUR PURCHASE DOES</div>
              <div className="text">
                <ul>
                  <li>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </li>
                  <li>Scelerisque congue maecenas eros, arcu vestibulum.</li>
                  <li>Aliquet tristique ipsum quis viverra.</li>
                  <li>Nisi, et a sagittis, egestas pharetra iaculis.</li>
                  <li>
                    Mattis euismod gravida semper neque viverra aliquam metus,
                    eget.
                  </li>
                  <li>
                    Sollicitudin feugiat eu venenatis tellus amet risus egestas.
                  </li>
                </ul>
              </div>
            </Parallax>
          </MediaQuery>

          <MediaQuery minWidth={1025}>
            <Parallax className="custom-class" x={[10, -20]} tagOuter="figure">
              <div className="your">YOUR QUESTIONS OUR ANSWERS</div>
              <div>
                <Faq />
              </div>
            </Parallax>
          </MediaQuery>
          <MediaQuery maxWidth={1024}>
            <Parallax className="custom-class" x={[0, 0]} tagOuter="figure">
              <div className="your">YOUR QUESTIONS OUR ANSWERS</div>
              <div>
                <Faq />
              </div>
            </Parallax>
          </MediaQuery>

          <Parallax
            className="custom-class"
            x={[10, -10]}
            tagOuter="figure"
          ></Parallax>
        </div>
      </div>
    </>
  );
}

export default Section4;
