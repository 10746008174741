import "./App.css";
import Appbar from "./components/Appbar/Appbar";
import Section1 from "./components/Section1/Section1";
import Section2 from "./components/Section2/Section2";
import Section3 from "./components/Section3/Section3";
import Section4 from "./components/Section4/Section4";
import Section5 from "./components/Section5/Section5";
import Section6 from "./components/Section6/Section6";
import Section7 from "./components/Section7/Section7";
import Section8 from "./components/Section8/Section8";
import * as Scroll from "react-scroll";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { ParallaxProvider } from "react-scroll-parallax";
import { useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";

function App() {
  return (
    <ParallaxProvider>
      <div className="App">
        <Navbar />
        <Section1 />
        <Section2 />
        {/* <Link
          activeClass="active"
          to="test1"
          spy={true}
          smooth={true}
          offset={50}
          duration={10000}
          delay={10000}
        > */}
        <Section3 />
        {/* </Link> */}

        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
      </div>
    </ParallaxProvider>
  );
}

export default App;
