import React from "react";
import "./Section8.css";
import { Grid, Box } from "@mui/material";

function Section8() {
  return (
    <div className="footer">
      <Box className="box8" sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid className="leftGrid" item xs={12} md={12} lg={6}>
            <div className="left">
              <div>
                <img
                  className="logo__alien"
                  src="./assets/logo__footer.png"
                  alt=""
                />
              </div>
              <div className="left__text">
                Changing the game through dynamic utility!
              </div>
            </div>
          </Grid>
          <Grid className="rightGrid1" item xs={6} md={6} lg={3}>
            <div className="right__text">
              <a href="/">
                <p className="right1">Home</p>
              </a>

              <a href="#Roadmap">
                <p className="right2">Terms & Conditions</p>
              </a>

              <a href="https://twitter.com/aliensocietycom ">
                <p className="right3">Smart Contract</p>
              </a>
            </div>
          </Grid>
          <Grid className="rightGrid2" item xs={6} md={6} lg={3}>
            <div className="right__text">
              <p className="right3">Join Newsletter</p>
              <p className="right3">
                Subscribe our newsletter to stay updated with all information
              </p>

              <div className="right4">
                {/* <div>
                  <input
                    placeholder="Enter your email"
                    className="discText"
                  ></input>
                </div>

                <div className="discdown">
                  <img
                    className="footerDisc"
                    src="./assets/Button2.svg"
                    alt=""
                  />
                </div> */}

                <form>
                  <input placeholder="Enter your email" />
                  <img
                    className="footerDisc"
                    src="./assets/Button.svg"
                    alt=""
                  />
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
      <hr
        style={{ width: "70vw", color: "white", backgroundColor: "white" }}
      ></hr>
      <div className="copy">
        Copyright © 2021 Alien Society. All rights reserved.
      </div>
    </div>
  );
}

export default Section8;
