import React from "react";
import "./Section5.css";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Parallax } from "react-scroll-parallax";
function Section5() {
  return (
    <div className="team">
      <div className="our__team">OUR TEAM</div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid item xs={12} md={12} lg={12}>
            <Grid justifyContent="center" alignItems="center" container>
              <Grid className="team__img__align" item xs={12} md={12} lg={4}>
                <Parallax
                  className="custom-class"
                  x={[-100, -35]}
                  tagOuter="figure"
                >
                  <img className="team__img" src="./assets/team1.png" alt="" />
                </Parallax>
              </Grid>
              <Grid className="team__descp" item xs={12} md={12} lg={8}>
                <div className="team__descp__title">Lorem ipsum</div>
                <div className="team__descp__text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Scelerisque congue maecenas eros, arcu vestibulum. Aliquet
                  tristique ipsum quis viverra. Nisi, et a sagittis, egestas
                  pharetra iaculis. Mattis euismod gravida semper neque viverra
                  aliquam metus, eget. Sollicitudin feugiat eu venenatis tellus
                  amet risus egestas. Eget aliquam eget ullamcorper a quis
                  ultrices in dignissim aliquam. Quam sed ultrices nisi, tellus
                  semper. Risus sed sit aliquam a sit velit.
                </div>
              </Grid>
              {/* <Grid className="team__descp" item xs={12} md={12} lg={2}></Grid> */}
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Grid justifyContent="space-evenly" alignItems="center" container>
              <Grid className="team__img__align" item xs={12} md={12} lg={4}>
                <Parallax
                  className="custom-class"
                  x={[-100, -35]}
                  tagOuter="figure"
                >
                  <img className="team__img" src="./assets/team2.png" alt="" />
                </Parallax>
              </Grid>
              <Grid className="team__descp" item xs={12} md={12} lg={8}>
                <div className="team__descp__title">Lorem ipsum</div>
                <div className="team__descp__text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Scelerisque congue maecenas eros, arcu vestibulum. Aliquet
                  tristique ipsum quis viverra. Nisi, et a sagittis, egestas
                  pharetra iaculis. Mattis euismod gravida semper neque viverra
                  aliquam metus, eget. Sollicitudin feugiat eu venenatis tellus
                  amet risus egestas. Eget aliquam eget ullamcorper a quis
                  ultrices in dignissim aliquam. Quam sed ultrices nisi, tellus
                  semper. Risus sed sit aliquam a sit velit.
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Grid justifyContent="space-evenly" alignItems="center" container>
              <Grid className="team__img__align" item xs={12} md={12} lg={4}>
                <Parallax
                  className="custom-class"
                  x={[-100, -35]}
                  tagOuter="figure"
                >
                  <img className="team__img" src="./assets/team3.png" alt="" />
                </Parallax>
              </Grid>
              <Grid className="team__descp" item xs={12} md={12} lg={8}>
                <div className="team__descp__title">Lorem ipsum</div>
                <div className="team__descp__text">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Scelerisque congue maecenas eros, arcu vestibulum. Aliquet
                  tristique ipsum quis viverra. Nisi, et a sagittis, egestas
                  pharetra iaculis. Mattis euismod gravida semper neque viverra
                  aliquam metus, eget. Sollicitudin feugiat eu venenatis tellus
                  amet risus egestas. Eget aliquam eget ullamcorper a quis
                  ultrices in dignissim aliquam. Quam sed ultrices nisi, tellus
                  semper. Risus sed sit aliquam a sit velit.
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Section5;
